import React, { useRef, useState } from 'react';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';

const GoogleAddressField = ({
  initialAddressValue, id, setFieldValue, country, setAddressFromGoogle,
}) => {
  const [ isFocused, setIsFocused ] = useState(false);
  const input = useRef(null);

  const getAddressData = place => {
    const output = {
      address: '',
      city: '',
      state: '',
      zipcode: '',
      country: '',
    };

    place.address_components.reverse().forEach(i => {
      i.types.forEach(j => {
        if (j === 'postal_code_prefix' || j === 'postal_code') {
          output.zipcode = i.long_name;
        }
        if (j === 'administrative_area_level_2' || j === 'locality' || j === 'postal_town') {
          output.city = i.long_name;
        }
        if (j === 'route') {
          output.address = i.long_name;
        }
        if (j === 'administrative_area_level_1') {
          output.state = i.long_name;
        }
        if (j === 'country') {
          output.country = i.short_name;
        }
      });
    });

    return output;
  };

  const addressSelectHandle = async value => {
    const [ place ] = await geocodeByPlaceId(value.place_id);
    const addressResult = getAddressData(place);

    const output = {
      ...addressResult,
      address: value.structured_formatting.main_text
      || place.formatted_address
      || value.description
      || addressResult.address,
    };

    setAddressFromGoogle(output);
  };

  const onSelectHandle = (e, cb, suggestion) => {
    e.preventDefault();
    cb(suggestion, e);
  };

  return (
    <GooglePlacesAutocomplete
      initialValue={initialAddressValue ?? ''}
      onSelect={value => addressSelectHandle(value)}
      minLengthAutocomplete={1}
      autocompletionRequest={{
        componentRestrictions: {
          country,
        },
      }}
      renderInput={props => (
        <input
          {...props}
          ref={input}
          onChangeCapture={e => setFieldValue(id, e.target.value, false)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
      )}
      renderSuggestions={(active, suggestions, onSelectSuggestion) => (
        <div
          className={`suggestions-container ${!isFocused ? 'hidden' : ''}`}
          onMouseDown={e => e.preventDefault()}
        >
          {suggestions.map(suggestion => (
            <div
              className='suggestion'
              onClick={event => onSelectHandle(event, onSelectSuggestion, suggestion)}
            >
              {suggestion.description}
            </div>
          ))}
        </div>
      )}
      placeholder='Type your address here ...'
      apiKey={GOOGLE_API_KEY}
    />
  );
};

export default GoogleAddressField;
