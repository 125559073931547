import React from 'react';

const AccountCard = ({
  bill, setShowModalAccountEditCardHandle, cardIcon, key,
}) => (
  <div key={key} className='account-card'>
    <div className='account-card-info'>
      { !!bill.is_default && (
        <div className='account-card__primary'>
          Primary
        </div>
      )}
      <div className='account-card__label'>
        Credit or Debit Card
      </div>
      <div className='account-card-wrap'>
        <div className='account-card__icon'>
          <img src={cardIcon} alt='card type' />
        </div>
        <div className='account-card__number'>
          •••• •••• ••••
          {' '}
          {bill.last4}
        </div>
      </div>
      <div className='account-address-wrap'>
        <div className='account-card__label'>
          Billing address
        </div>
        <div className='account-address'>
          {/* [TODO] Refactor address view */}
          {bill.address.address
            ? bill.address.address
            : bill.address.address }
          {' '}
          <br />
          {bill.address.city
            ? bill.address.city
            : bill.address.city }
          {' '}
          {
            (bill.address.state || bill.address.state) && (
              <React.Fragment key='bill'>
                <br />
                {bill.address.state
                  ? bill.address.state
                  : bill.address.state }
                ,
                {' '}
              </React.Fragment>
            )
          }
          {bill.address.country
            ? bill.address.country
            : bill.address.country }

          {' '}
          {bill.address.zipcode
            ? bill.address.zipcode
            : bill.address.zipcode }

        </div>
      </div>
    </div>
    <div className='account-card-buttons'>
      <button
        type='button'
        className='btn'
        onClick={() => setShowModalAccountEditCardHandle(bill)}
      >
        Edit
      </button>
    </div>
  </div>
);

export default AccountCard;
