import React, { useEffect, useState } from 'react';
import { Field } from 'formik';
import InputMask from 'react-input-mask';
import { SUPPORTED_REGIONS } from '../../shared/enums';
import GoogleAddressField from './GoogleAddressField';
import AddressFieldSelectCountry from './AddressFieldSelectCountry';

const ModalAccountEditAddressField = ({
  label, className, id, type, placeholder,
  name, mask, errors, setFieldValue, values, autocomplete, country, setAddressFromGoogle,
}) => {
  const [ isFocused, setIsFocused ] = useState(false);
  const [ isError, setIsError ] = useState(false);

  const [ initialAddressValue, setinitialAddressValue ] = useState('');

  useEffect(() => {
    if (id === 'address.address') {
      setinitialAddressValue(values.address.address);
      // TODO Refactor to unify initial address value
      // console.log(values[id.split('.')[0]]);
    } else if (id === 'address') {
      setinitialAddressValue(values.address);
    }
  }, [ values ]);

  useEffect(() => {
    if (Object.keys(errors).length) {
      const keysArr = id.split('.');
      if (keysArr.length > 1) {
        setIsError(!!errors[keysArr[0]]?.[keysArr[1]]);
      } else {
        setIsError(!!errors[keysArr[0]]);
      }
    } else {
      setIsError(false);
    }
  }, [ errors ]);

  return (
    <div className={`modal-edit-address-input modal-edit-address-${className} ${isFocused ? 'focused' : ''} ${isError ? 'input-error' : ''}`}>
      <label className='modal-edit-address-input__label' htmlFor={id}>
        {label}
      </label>
      <div className='modal-edit-address-input__input'>
        {
          !!(id === 'address.address' || id === 'address') && (
            <GoogleAddressField
              id={id}
              setFieldValue={setFieldValue}
              initialAddressValue={initialAddressValue}
              country={country}
              setAddressFromGoogle={setAddressFromGoogle}
            />
          )
        }
        {
          !!(id === 'address.state' && Object.keys(SUPPORTED_REGIONS.EU).includes(values.address.state)) && (
            <AddressFieldSelectCountry
              id={id}
              placeholder={placeholder}
              initialValue={values.address.state}
              values={values}
              setFieldValue={setFieldValue}
            />
          )
        }
        {
          !!((id === 'state') && Object.keys(SUPPORTED_REGIONS.EU).includes(values.state)) && (
            <AddressFieldSelectCountry
              id={id}
              placeholder={placeholder}
              initialValue={values.state}
              values={values}
              setFieldValue={setFieldValue}
            />
          )
        }
        {
          !!((id !== 'address.address' && id !== 'address'
          && !(id === 'state' && Object.keys(SUPPORTED_REGIONS.EU).includes(values.state))
          && !(id === 'address.state' && Object.keys(SUPPORTED_REGIONS.EU).includes(values.address.state))))
            && (
              <Field
                type={type}
                id={id}
                name={name}
              >
                {({ field }) => (
                  <InputMask
                    {...field}
                    mask={mask}
                    maskChar={null}
                    placeholder={placeholder}
                    autoComplete={autocomplete}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                  />
                )}
              </Field>
            )
        }
      </div>
    </div>
  );
};

export default ModalAccountEditAddressField;
