import React, { useEffect, useState } from 'react';
import { SUPPORTED_REGIONS } from '../../shared/enums';

const AddressFieldSelect = ({
  placeholder, id, initialValue, values, setFieldValue,
}) => {
  const [ input, setInput ] = useState('');
  const [ inputSuggestions, setInputSuggestions ] = useState([]);
  const [ pickedSuggestion, setPickedSuggestion ] = useState('');

  useEffect(() => {
    if (SUPPORTED_REGIONS.EU[initialValue]) {
      setInput(SUPPORTED_REGIONS.EU[initialValue]);
      setPickedSuggestion(SUPPORTED_REGIONS.EU[initialValue]);
    }
  }, [ initialValue ]);

  useEffect(() => {
    const suggestions = [];

    Object.values(SUPPORTED_REGIONS.EU).forEach(value => {
      if (value.toLowerCase().includes(input.toLowerCase())) {
        suggestions.push(value);
      }
    });

    setInputSuggestions(suggestions);
  }, [ input ]);

  const onSelectCountrySuggestion = value => {
    setInput(value);
    setPickedSuggestion(value);
  };

  useEffect(() => {
    const keyCountry = Object.keys(SUPPORTED_REGIONS.EU).find(key => SUPPORTED_REGIONS.EU[key] === pickedSuggestion);
    if (keyCountry && initialValue !== keyCountry) {
      setFieldValue(id, keyCountry);
    }
  }, [ pickedSuggestion ]);

  return (
    <div>
      <input type='text' placeholder={placeholder} value={input} onChange={e => setInput(e.target.value)} />
      {!!(input.length && input !== pickedSuggestion) && (
        <div className='suggestions-container'>
          {inputSuggestions.map(suggestion => (
            <div
              className='suggestion'
              onClick={() => onSelectCountrySuggestion(suggestion)}
            >
              {suggestion}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AddressFieldSelect;
