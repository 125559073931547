import React from 'react';
import CheckboxIcon from '../../assets/uploads/checkbox.svg';

const Checkbox = ({ value, setFieldValue, id }) => (
  <div className='checkbox' onClick={() => setFieldValue(id, +!value, false)}>
    {!!value && <img src={CheckboxIcon} alt='' />}
  </div>
);

export default Checkbox;
