import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import AccountCard from './AccountCard';
import VisaIcon from '../../assets/uploads/visa-icon.svg';

const AccountOrder = ({
  bills, width,
  setShowModalAccountEditCardHandle,
  setShowModalAccountAddCardHandle,
}) => {
  const [ sliderSlides, setSliderSlides ] = useState([]);

  useEffect(() => {
    const slides = bills.map(bill => (
      <SwiperSlide key={bill.id}>
        <AccountCard
          bill={bill}
          cardIcon={VisaIcon}
          setShowModalAccountEditCardHandle={setShowModalAccountEditCardHandle}
        />
      </SwiperSlide>
    ));

    setSliderSlides(slides);
  }, [ bills ]);

  return (
    <div>
      <div className='account-header'>
        <h2 className='account-subtitle'>
          Next refill due
          {' '}
          {bills[0].next_refill}
        </h2>
      </div>
      <div className='account-cards'>
        { (width > 768) ? bills.map(bill => (
          <AccountCard
            key={bill.id}
            bill={bill}
            cardIcon={VisaIcon}
            setShowModalAccountEditCardHandle={setShowModalAccountEditCardHandle}
          />
        )) : (
          <Swiper
            spaceBetween={16}
            slidesPerView='auto'
          >
            {sliderSlides}
          </Swiper>
        )}
      </div>
      <div className='account-buttons'>
        <button
          type='button'
          className='btn btn--secondary-white'
          onClick={() => setShowModalAccountAddCardHandle(bills[0])}
        >
          Add new
        </button>
      </div>
    </div>
  );
};

export default AccountOrder;
