import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import loadable from '@loadable/component';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import {
  clearAccount, fetchAccount, selectAccountData, selectAccountError, selectAccountStatus,
} from '../features/account/accountSlice';
import { STATUS } from '../../shared/enums';
import { useWindowDimensions } from '../hooks';
import AccountOrder from './AccountOrder';
import ModalAccountAddCard from './ModalAccountAddCard';

const ModalAccountEditCard = loadable(() => import('./ModalAccountEditCard'));

const AccountPageContent = props => {
  const {
    auth,
  } = props;

  const { width } = useWindowDimensions();

  const dispatch = useDispatch();
  const account = useSelector(selectAccountData);
  const status = useSelector(selectAccountStatus);
  const error = useSelector(selectAccountError);
  const [ accountByOrder, setAccountByOrder ] = useState([]);
  const [ showModalAccountEditCard, setShowModalAccountEditCard ] = useState(false);
  const [ showModalAccountAddCard, setShowModalAccountAddCard ] = useState(false);

  const [ isAccountFound, setIsAccountFound ] = useState(false);
  const [ activeBill, setActiveBill ] = useState(null);

  useEffect(() => () => dispatch(clearAccount()), []);

  useEffect(() => {
    if (auth && status === STATUS.IDLE) dispatch(fetchAccount());
    return null;
  }, [ auth ]);

  const setShowModalAccountEditCardHandle = bill => {
    setActiveBill(bill);
    setShowModalAccountEditCard(true);
  };

  const setShowModalAccountAddCardHandle = bill => {
    setActiveBill(bill);
    setShowModalAccountAddCard(true);
  };

  useEffect(() => {
    setIsAccountFound(Array.isArray(account) && account.length);
  }, [ account ]);

  useEffect(() => {
    const output = {};

    if (isAccountFound) {
      account.forEach(bill => {
        if (output[bill.order_id]) {
          output[bill.order_id].push(bill);
        } else {
          output[bill.order_id] = [ bill ];
        }
      });
    }

    setAccountByOrder(output);
  }, [ isAccountFound, account ]);

  const name = auth?.firstName || 'fellow air breather';

  return (
    <main className='main account'>
      <Helmet>
        <title>{props?.route?.meta?.title}</title>
      </Helmet>
      {
        ((!error && status === STATUS.SUCCEEDED) && isAccountFound) ? (
          <div>
            <h2>{`Hey ${name} 👋`}</h2>
            <div className='account-wrap'>
              <p className='account-title'>Billing information</p>
            </div>
            {
              Object.keys(accountByOrder).map(key => (
                <AccountOrder
                  key={key}
                  bills={accountByOrder[key]}
                  width={width}
                  setShowModalAccountEditCardHandle={setShowModalAccountEditCardHandle}
                  setShowModalAccountAddCardHandle={setShowModalAccountAddCardHandle}
                />
              ))
            }
          </div>
        ) : null
      }
      {
        ((!error && status === STATUS.SUCCEEDED) && !isAccountFound) ? (
          <div className='account-noactive'>
            No active Auto-Refills found. Your account info for any active Auto-Refills will show up here.
            {' '}
            <br />
            <Link to='/dashboard'>
              <span className='account-noactive__link'>Activate Auto-Refill now</span>
            </Link>
          </div>
        ) : null
      }

      <ModalAccountEditCard
        show={showModalAccountEditCard}
        setShow={setShowModalAccountEditCard}
        bill={activeBill}
        accountByOrder={accountByOrder}
      />
      <ModalAccountAddCard
        show={showModalAccountAddCard}
        setShow={setShowModalAccountAddCard}
        bill={activeBill}
      />
    </main>
  );
};

export default AccountPageContent;
