import React, { useEffect, useState } from 'react';
import { Field } from 'formik';
import InputMask from 'react-input-mask';
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import Checkbox from './Checkbox';
import { SUPPORTED_REGIONS } from '../../shared/enums';
import GoogleAddressField from './GoogleAddressField';
import AddressFieldSelectCountry from './AddressFieldSelectCountry';

/**
 * @param label
 * @param className
 * @param id
 * @param type
 * @param placeholder
 * @param name
 * @param stripeValidityCheck
 * @param stripeValidityUnCheck
 * @param values
 * @param setFieldValue
 * @param key
 * @param mask
 * @param errors
 * @param autocomplete
 * @param country
 * @param setAddressFromGoogle
 * @returns {JSX.Element}
 * @constructor
 */
const ModalAccountAddCardField = ({
  label, className, id, type, placeholder, name, stripeValidityCheck, stripeValidityUnCheck,
  values, setFieldValue, key, mask, errors, autocomplete, country, setAddressFromGoogle,
}) => {
  const [ isFocused, setIsFocused ] = useState(false);
  const [ isError, setIsError ] = useState(false);
  const [ initialAddressValue, setinitialAddressValue ] = useState('');

  const style = { base: { fontSize: '18px', '::placeholder': { color: '#4B4B4B' }}};

  useEffect(() => {
    if (id === 'address.address') {
      setinitialAddressValue(values.address.address);
    } else if (id === 'address') {
      setinitialAddressValue(values.address);
    }
  }, [ values ]);

  useEffect(() => {
    if (Object.keys(errors).length) {
      const keysArr = id.split('.');
      if (keysArr.length > 1) {
        setIsError(!!errors[keysArr[0]]?.[keysArr[1]]);
      } else {
        setIsError(!!errors[keysArr[0]]);
      }
    } else {
      setIsError(false);
    }
  }, [ errors ]);

  return (
    <div
      key={key}
      className={`modal-account-card-field ${type === 'checkbox' ? 'modal-account-card-checkbox' : ''} modal-account-card-${className} ${isFocused ? 'focused' : ''} ${isError ? 'input-error' : ''}`}
    >
      <label className='modal-account-card-field__label' htmlFor={id}>
        {label}
      </label>
      <div className='modal-account-card-field__input'>
        {(type === 'checkbox') && (
          <Checkbox
            value={values.is_default}
            setFieldValue={setFieldValue}
            id={id}
          />
        )}
        {
          !!(id === 'address.address' || id === 'address') && (
            <GoogleAddressField
              id={id}
              setFieldValue={setFieldValue}
              initialAddressValue={initialAddressValue}
              country={country}
              setAddressFromGoogle={setAddressFromGoogle}
            />
          )
        }
        {
          (id === 'number') && (
            <CardNumberElement
              options={{ placeholder, style }}
              onChange={() => stripeValidityCheck('cardNumber')}
              onBlur={() => stripeValidityCheck('cardNumber')}
              onFocus={() => stripeValidityUnCheck('cardNumber')}
            />
          )
        }
        {
          (id === 'expiration') && (
            <CardExpiryElement
              options={{ placeholder, style }}
              onChange={() => stripeValidityCheck('cardExpiry')}
              onBlur={() => stripeValidityCheck('cardExpiry')}
              onFocus={() => stripeValidityUnCheck('cardExpiry')}
            />
          )
        }
        {
          (id === 'cvc') && (
            <CardCvcElement
              options={{ placeholder, style }}
              onChange={() => stripeValidityCheck('cardCvc')}
              onBlur={() => stripeValidityCheck('cardCvc')}
              onFocus={() => stripeValidityUnCheck('cardCvc')}
            />
          )
        }
        {
          !!(id === 'address.state' && Object.keys(SUPPORTED_REGIONS.EU).includes(values.address.state)) && (
            <AddressFieldSelectCountry
              id={id}
              placeholder={placeholder}
              initialValue={values.address.state}
              values={values}
              setFieldValue={setFieldValue}
            />
          )
        }
        {
          !!((id === 'state') && Object.keys(SUPPORTED_REGIONS.EU).includes(values.state)) && (
            <AddressFieldSelectCountry
              id={id}
              placeholder={placeholder}
              initialValue={values.state}
              values={values}
              setFieldValue={setFieldValue}
            />
          )
        }
        {!!((id !== 'address.address' && id !== 'number' && id !== 'expiration' && id !== 'cvc' && id !== 'address'
          && !(id === 'state' && Object.keys(SUPPORTED_REGIONS.EU).includes(values.state))
          && !(id === 'address.state' && Object.keys(SUPPORTED_REGIONS.EU).includes(values.address.state)))
          && type !== 'checkbox') && (
          <Field
            type={type}
            id={id}
            name={name}
            className={`${type === 'checkbox' ? 'checkbox-hidden' : ''}`}
          >
            {({ field }) => (
              <InputMask
                {...field}
                mask={mask}
                maskChar={null}
                placeholder={placeholder}
                autoComplete={autocomplete}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
              />
            )}
          </Field>
        )}
      </div>
    </div>
  );
};

export default ModalAccountAddCardField;
