import React, { useEffect, useState } from 'react';
import {
  useFormik, FormikProvider,
} from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import ModalAccountEditAddressField from './ModalAccountEditAddressField';
import { convertEmptyStringToNull } from '../../shared/utils';
import ModalBase from './ModalBase';
import ModalAccountAddCardField from './ModalAccountAddCardField';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { postStripe } from '../features/stripe/stripeSlice';
import { SUPPORTED_REGIONS } from '../../shared/enums';
import convertNullToEmptyString from '../../shared/utils/convertNullToEmptyString';
import Checkbox from './Checkbox';
import { REGEXP, REGION_LABELS } from '../../shared/constants';
import { clearGlobalErrors } from '../features/root/rootSlice';

const validationSchema = Yup.object().shape({
  customer: Yup.string()
    .nullable(true),
  name: Yup.string()
    .matches(REGEXP.ADDRESS, 'Only English letters')
    .required('Name is required'),
  address: Yup.object().shape({
    type: Yup.string()
      .required('Type is required'),
    order_id: Yup.string()
      .required('Order is required'),
    address: Yup.string()
      .matches(REGEXP.ADDRESS_WITH_NUMS, 'Invalid characters in the address')
      .required('Address is required'),
    address2: Yup.string()
      .nullable(true)
      .matches(REGEXP.ADDRESS_WITH_NUMS, 'Invalid characters in the address'),
    city: Yup.string()
      .matches(REGEXP.ADDRESS, 'Only English letters')
      .required('City is required'),
    state: Yup.string()
      .matches(REGEXP.ADDRESS, 'Only English letters')
      .required('State is required'),
    zipcode: Yup.string()
      .required('Zipcode is required'),
    country: Yup.string()
      .required('Country is required')
      .matches(REGEXP.ADDRESS, 'Only English letters')
      .test('len', 'Must be exactly 2 characters', val => (val.length < 3 || val === 'N/A')),
    name: Yup.string()
      .matches(REGEXP.ADDRESS, 'Only English letters')
      .required('Name is required'),
  }),
});

/**
 * @param paymentMethod
 * @returns {[{autocomplete: string, name: string, className: string, label: string, id: string, placeholder: string, type: string},{autocomplete: string, name: string, className: string, label: string, id: string, placeholder: string, type: string, mask: string},{autocomplete: string, name: string, className: string, label: string, id: string, placeholder: (string|string), type: string, mask: string},{autocomplete: string, name: string, className: string, label: string, id: string, placeholder: string, type: string, mask: string}]}
 */
const getCardFields = (paymentMethod = undefined) => {
  let expMonth;
  let expYear;

  if (paymentMethod) {
    expMonth = paymentMethod.exp_month <= 9
      ? `0${paymentMethod.exp_month}` : paymentMethod.exp_month;
    expYear = paymentMethod.exp_year.toString().substr(-2);
  }

  return [
    {
      name: 'name',
      label: 'Card Holder',
      className: 'name',
      type: 'text',
      id: 'name',
      placeholder: '',
      autocomplete: 'cc-name',
    },
    {
      name: 'number',
      label: 'Number',
      className: 'number',
      type: 'text',
      id: 'number',
      placeholder: `•••• •••• •••• ${paymentMethod?.last4 || '••••'}`,
      mask: '9999 9999 9999 9999',
      autocomplete: 'cc-number',
    },
    {
      name: 'expiration',
      label: 'Expiration',
      className: 'expiration',
      type: 'text',
      id: 'expiration',
      placeholder: expMonth && expYear ? `${expMonth}/${expYear}` : '••/••',
      mask: '99/99',
      autocomplete: 'cc-exp',
    },
    {
      name: 'cvc',
      label: 'CVC',
      className: 'cvc',
      type: 'number',
      id: 'cvc',
      placeholder: '•••',
      mask: '999',
      autocomplete: 'cc-csc',
    },
  ];
};

const accountFields = [
  {
    name: 'address.name',
    label: 'Full Name',
    className: 'fullname',
    id: 'address.name',
    type: 'text',
    placeholder: 'Add your name here ...',
  },
  {
    name: 'address.address',
    label: 'Address',
    className: 'address',
    id: 'address.address',
    type: 'text',
    placeholder: 'Add your address here ...',
    autocomplete: 'off',
  },
  {
    name: 'address.address2',
    label: 'Unit/Apartment #',
    className: 'unit',
    id: 'address.address2',
    type: 'text',
    placeholder: '(Optional)',
    autocomplete: 'off',
  },
  {
    name: 'address.city',
    label: 'City',
    className: 'city',
    id: 'address.city',
    type: 'text',
    placeholder: 'Add your city here ...',
  },
  {
    name: 'address.state',
    label: 'State',
    className: 'state',
    type: 'text',
    id: 'address.state',
    placeholder: '',
    autocomplete: 'off',
  },
  {
    name: 'address.zipcode',
    label: 'Zipcode',
    className: 'zipcode',
    type: 'text',
    id: 'address.zipcode',
    placeholder: '',
  },
];

const preInitialValues = {
  name: '',
  customer: '',
  address: {
    order_id: '',
    type: 'billing',
    address: '',
    address2: '',
    city: '',
    state: '',
    zipcode: '',
    country: '',
    name: '',
  },
};

/**
 * @param initialValues
 * @param bill
 * @returns {*&{address: (*&{country: (*|string), order_id: (string|*)}), customer: (*|string)}}
 */
const getInitilaValuesFromBill = (initialValues, bill) => ({
  ...initialValues,
  customer: bill?.stripe_customer_id ?? '',
  address: {
    ...initialValues.address,
    country: bill?.addresses[0]?.country ?? '',
    order_id: bill?.addresses[0]?.order_id ?? '',
  },
});

const ModalAccountAddCard = props => {
  const { bill, setShow, show } = props;
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [ isUseShippingAddress, setIsUseShippingAddress ] = useState(false);
  const [ shippingAddress, setShippingAddress ] = useState(null);
  const [ country, setCountry ] = useState('');
  const [ isCountry, setIsCountry ] = useState(false);
  const [ isShippingAddessChanged, setShippingAddressChanged ] = useState(false);
  const [ isCountryEU, setIsCountryEU ] = useState(false);
  const [ cardFields, setCardFields ] = useState(getCardFields());
  const [ customStripeValidationErrors, setCustomStripeValidationErrors ] = useState({});

  /**
   * @type {Readonly<{cardCvc: string, cardExpiry: string, cardNumber: string}>}
   */
  const replacements = Object.freeze({
    cardNumber: 'number',
    cardExpiry: 'expiration',
    cardCvc: 'cvc',
  });

  useEffect(() => {
    if (shippingAddress) {
      setIsUseShippingAddress(true);
    }
  }, [ shippingAddress ]);

  const stripeValidityCheck = keyInput => {
    const validationErrors = { ...customStripeValidationErrors };

    if (stripe && elements) {
      const cardField = elements.getElement(keyInput);
      const isInvalid = cardField?._invalid;
      const isEmpty = cardField?._empty;
      const isValid = !(isEmpty || isInvalid);
      const keyFieldsError = replacements[keyInput];
      if (!isValid) {
        const updatedValidationErrors = {
          ...validationErrors,
          [keyFieldsError]: 'Test Error',
        };
        if (Object.keys(updatedValidationErrors).length !== Object.keys(validationErrors).length) {
          setCustomStripeValidationErrors(updatedValidationErrors);
        }
      } else if (Object.keys(validationErrors).length) {
        delete validationErrors[keyFieldsError];
        setCustomStripeValidationErrors(validationErrors);
      }
    }
  };

  const stripeValidityUnCheck = keyInput => {
    const validationErrors = { ...customStripeValidationErrors };
    const keyFieldsError = replacements[keyInput];
    const cardField = elements.getElement(keyInput);
    const isInvalid = cardField?._invalid;
    const isEmpty = cardField?._empty;
    if (!isInvalid && !isEmpty) {
      delete validationErrors[keyFieldsError];
      setCustomStripeValidationErrors(validationErrors);
    }
  };

  const stripeValidityComplexCheck = () => {
    const elementsCheck = Object.keys(replacements);
    const complexElements = {};
    if (stripe && elements) {
      elementsCheck.forEach(key => {
        const cardFieldCheck = elements.getElement(key);
        const isInvalid = cardFieldCheck?._invalid;
        const isEmpty = cardFieldCheck?._empty;
        if (isInvalid || isEmpty) {
          complexElements[replacements[key]] = 'Error Test';
        }
      });
      setCustomStripeValidationErrors(complexElements);
    }
    return Boolean(Object.keys(complexElements).length);
  };

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    const output = { ...values };
    const isErrors = stripeValidityComplexCheck();
    if (!isErrors) {
      if (Object.keys(SUPPORTED_REGIONS.EU).includes(values.address.state)) {
        output.address.country = values.address.state;
        output.address.state = 'N/A';
      }
      const { error } = await dispatch(postStripe({
        stripe,
        payload: convertEmptyStringToNull(output),
        card: elements.getElement(CardNumberElement),
      }));

      if (!error) {
        resetForm();
        setCustomStripeValidationErrors({});
        setShow(false);
      }
    }
    setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: preInitialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit,
  });

  const {
    errors,
    submitCount,
    initialValues,
    initialErrors,
    values,
    setValues,
  } = formik;

  useEffect(() => {
    const internalizedShippingAddress = getInternalizationChanges({
      ...values,
      address: {
        ...shippingAddress,
        id: undefined,
      },
    });

    const formikValues = {
      ...formik.values,
      address: {
        ...formik.values.address,
        id: undefined,
      },
    };

    if (JSON.stringify(formikValues) !== JSON.stringify(preInitialValues) && shippingAddress && formikValues.address.address) {
      if (JSON.stringify(formikValues) !== JSON.stringify(internalizedShippingAddress)) {
        setShippingAddressChanged(true);
      } else {
        setShippingAddressChanged(false);
      }
    }
  }, [ formik.values, preInitialValues, values, shippingAddress ]);

  const setIsShippingAdressStatus = value => {
    if (value) {
      setAddressFromShipping();
    } else {
      clearAddressInfo();
    }
    setIsUseShippingAddress(value);
    setShippingAddressChanged(!value);
  };

  const getAccountField = id => accountFields.findIndex(v => v.id === id);

  const setFieldLabels = (cityIndex, stateIndex, zipcodeIndex, labelsMap) => {
    accountFields[cityIndex].label = labelsMap.city;
    accountFields[stateIndex].label = labelsMap.state;
    accountFields[zipcodeIndex].label = labelsMap.zipcode;
    if (width <= 767) {
      accountFields[stateIndex].placeholder = labelsMap.placeholders.state;
      accountFields[zipcodeIndex].placeholder = labelsMap.placeholders.zipcode;
    } else {
      accountFields[stateIndex].placeholder = '';
      accountFields[zipcodeIndex].placeholder = '';
    }
  };

  const setFieldLabelsByCountry = country => {
    const cityIndex = getAccountField('address.city');
    const stateIndex = getAccountField('address.state');
    const zipcodeIndex = getAccountField('address.zipcode');

    if (REGION_LABELS[country]) {
      setFieldLabels(cityIndex, stateIndex, zipcodeIndex, REGION_LABELS[country]);
    } else if (Object.keys(SUPPORTED_REGIONS.EU).includes(country)) {
      setFieldLabels(cityIndex, stateIndex, zipcodeIndex, REGION_LABELS.EU);
    }
  };

  useEffect(() => {
    if (bill) {
      setFieldLabelsByCountry(bill.addresses[0].country);
    }
  }, [ width ]);

  const getInternalizationChanges = values => {
    const output = { ...values };

    if (Object.keys(SUPPORTED_REGIONS.EU).includes(values.address.country)) {
      output.address.state = values.address.country;
      output.address.country = values.address.state;
    }

    return output;
  };

  const onCancel = () => {
    setShow(false);
  };

  const resetInitialValues = () => {
    setValues(preInitialValues);
    setIsUseShippingAddress(false);
    setShippingAddress(null);
  };

  const getShippingAddress = addresses => {
    const address = addresses.filter(value => value.type === 'shipping');
    const output = address.length ? address[0] : null;
    return {
      ...output,
      type: 'billing',
    };
  };

  const setAddressFromShipping = () => {
    setValues(getInternalizationChanges({
      ...values,
      address: {
        ...shippingAddress,
        id: undefined,
      },
    }));
  };

  const clearAddressInfo = () => {
    setValues({
      ...values,
      address: {
        ...preInitialValues.address,
        order_id: bill?.addresses[0]?.order_id ?? '',
        country: bill?.addresses[0]?.country ?? '',
      },
    });
  };

  const setAddressFromGoogle = rawAddress => {
    setValues({
      ...values,
      address: {
        ...preInitialValues.address,
        ...rawAddress,
        name: values.address.name,
        order_id: bill?.addresses[0]?.order_id ?? '',
        ...((isCountry && bill) && {
          country,
        }),
        ...((isCountryEU && bill) && {
          state: rawAddress.country,
        }),
      },
    });
  };

  useEffect(() => {
    if (isUseShippingAddress) {
      setAddressFromShipping();
    } else {
      clearAddressInfo();
    }
  }, [ isUseShippingAddress ]);

  const setCardName = (output, bill) => ({
    ...output,
    name: bill?.payment_method?.name || '',
  });

  useEffect(() => {
    if (bill && show) {
      const country = bill.addresses[0]?.country;
      setCountry(country);
      setIsCountry(REGION_LABELS[country]);
      setIsCountryEU(Object.keys(SUPPORTED_REGIONS.EU).includes(country));
      setShippingAddress(convertNullToEmptyString(getShippingAddress(bill.addresses)));
      setCardFields(getCardFields(bill.payment_method));
      setFieldLabelsByCountry(country);
      let output = {};
      stripeValidityComplexCheck();
      output = getInitilaValuesFromBill(initialValues, bill);
      output = getInternalizationChanges(output);
      output = setCardName(output, bill);
      setValues(convertNullToEmptyString(output));
    } else {
      resetInitialValues();
      formik.resetForm({
        errors: {},
      });
      setCustomStripeValidationErrors({});
    }
  }, [ bill, show ]);

  return (
    <ModalBase {...props} width={1282} className='modal-account'>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <h2 className='modal__title'>Update Billing Info</h2>
          <div className='modal-account-wrap'>
            <div className='modal-account-card'>
              <p className='account-card__label'>
                Credit or Debit Card
              </p>
              <div className='modal-account-card-wrap'>
                {
                  cardFields.map(field => (
                    <ModalAccountAddCardField
                      key={field.id}
                      label={field.label}
                      className={field.className}
                      id={field.id}
                      type={field.type}
                      name={field.name}
                      placeholder={field.placeholder}
                      values={formik.values}
                      setFieldValue={formik.setFieldValue}
                      mask={field.mask}
                      autocomplete={field.autocomplete}
                      errors={submitCount > 0 ? { ...errors, ...customStripeValidationErrors } : {}}
                      country={country}
                      stripeValidityUnCheck={stripeValidityUnCheck}
                      stripeValidityCheck={stripeValidityCheck}
                      setAddressFromGoogle={setAddressFromGoogle}
                    />
                  ))
                }
              </div>
            </div>
            <div className='modal-account-address'>
              <div className='modal-account-address-header'>
                <p className='account-card__label account-address__label'>
                  Billing Address
                </p>
                {shippingAddress && (
                  <div className='modal-account-address-shipping'>
                    <Checkbox
                      value={isUseShippingAddress && !isShippingAddessChanged}
                      setFieldValue={(_, value) => setIsShippingAdressStatus(value)}
                    />
                    <p className='modal-account-address-shipping__label'>
                      Use Shipping Address
                    </p>
                  </div>
                ) }
              </div>

              <div className='modal-account-address-wrap'>
                {
                  (width > 768) ? accountFields.map(field => (
                    <ModalAccountEditAddressField
                      key={field.id}
                      label={field.label}
                      className={field.className}
                      id={field.id}
                      type={field.type}
                      name={field.name}
                      placeholder={field.placeholder}
                      setFieldValue={formik.setFieldValue}
                      values={values}
                      mask={field.mask}
                      autocomplete={field.autocomplete}
                      errors={submitCount > 0 ? errors : {}}
                      country={country}
                      setAddressFromGoogle={setAddressFromGoogle}
                    />
                  )) : accountFields.map(field => (
                    <ModalAccountAddCardField
                      key={field.id}
                      label={field.label}
                      className={field.className}
                      id={field.id}
                      type={field.type}
                      name={field.name}
                      placeholder={field.placeholder}
                      values={formik.values}
                      setFieldValue={formik.setFieldValue}
                      mask={field.mask}
                      autocomplete={field.autocomplete}
                      errors={submitCount > 0 ? errors : {}}
                      country={country}
                      setAddressFromGoogle={setAddressFromGoogle}
                    />
                  ))
                }
              </div>
            </div>
          </div>

          <div className='modal-delay-footer modal-edit-address-update'>
            <button
              type='submit'
              className='btn btn--primary'
              disabled={Object.keys({ ...errors, ...initialErrors, ...customStripeValidationErrors }).length && submitCount > 0}
            >
              Save
            </button>
            <div className='btn-cancel' onClick={onCancel}>
              <span className='cancel-span-btn'>
                Cancel
              </span>
            </div>
          </div>
        </form>
      </FormikProvider>
    </ModalBase>
  );
};

export default ModalAccountAddCard;
