/**
 *  convertNullToEmptyString in object
 *
 * @param {*} obj
 * @return {*}
 */
function convertNullToEmptyString(obj) {
  const newObj = {};
  Object.keys(obj).forEach(key => {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      newObj[key] = convertNullToEmptyString(obj[key]);
    } else if (obj[key] === null) {
      newObj[key] = '';
    } else {
      newObj[key] = obj[key];
    }
  });
  return newObj;
}

export default convertNullToEmptyString;
